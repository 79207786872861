import request from '@/utils/request'

//获取互动问答列表
export function getQaList(params) {
    return request({
        url: '/portal/qa/get-list',
        method: 'GET',
        params
    })
}

//获取互动问答主题详情
export function getQaInfo(params) {
    return request({
        url: '/portal/qa/get-info',
        method: 'GET',
        params
    })
}

//发布互动问答
export function getQaAdd(data) {
    return request({
        url: '/portal/qa/add',
        method: 'POST',
        data
    })
}

//删除互动问答
export function getQaDelete(data) {
    return request({
        url: '/portal/qa/delete',
        method: 'DELETE',
        data
    })
}

//修改互动问答
export function getQaEdit(data) {
    return request({
        url: '/portal/qa/edit',
        method: 'POST',
        data
    })
}

//用户收藏/取消收藏
export function setQaFavorite(data) {
    return request({
        url: '/portal/user-favorite/set',
        method: 'POST',
        data
    })
}

//互动问答点赞/取消点赞
export function setQaLike(data) {
    return request({
        url: '/portal/qa/like',
        method: 'POST',
        data
    })
}